import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import DigitalHeroesBlock from "@Js/Component/Block/DigitalHeroesBlock";
import Gutenberg from "@Js/Component/Gutenberg";
import WhatWeCanDoForYouBlock from "@Js/Component/Block/WhatWeCanDoForYouBlock";
import WhatWeStandForBlock from "@Js/Component/Block/WhatWeStandForBlock";

import * as classNames from "@Css/Component/Page/AboutUs.module.scss";

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        bottomImages: {
            bottomImageOne: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            bottomImageTwo: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
        };
        content?: string;
        extraContent?: string;
        seo: {
            canonical: string;
            metaDesc: string;
            metaKeywords: string;
            metaRobotsNofollow: string;
            metaRobotsNoindex: string;
            opengraphAuthor: string;
            opengraphDescription: string;
            opengraphModifiedTime: string;
            opengraphPublishedTime: string;
            opengraphPublisher: string;
            opengraphSiteName: string;
            opengraphTitle: string;
            opengraphType: string;
            opengraphUrl: string;
            schema: {
                raw: string;
            };
            title: string;
        };
        topImages: {
            topImageOne: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
            topImageTwo: {
                altText: string;
                localFile: {
                    childImageSharp: {
                        gatsbyImageData: IGatsbyImageData;
                    };
                };
            };
        };
    };
};

/**
 * @type AboutUsProps
 */
export type AboutUsProps = PageProps;

/**
 * @const AboutUs
 */
const AboutUs = (props: AboutUsProps, ref: Ref<HTMLElement>) => {
    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    const data: WordpressPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isAboutUsPage: { eq: true }) {
                bottomImages {
                    bottomImageOne {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    bottomImageTwo {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                }
                content
                extraContent
                seo {
                    canonical
                    metaDesc
                    metaKeywords
                    metaRobotsNofollow
                    metaRobotsNoindex
                    opengraphAuthor
                    opengraphDescription
                    opengraphModifiedTime
                    opengraphPublishedTime
                    opengraphPublisher
                    opengraphSiteName
                    opengraphTitle
                    opengraphType
                    opengraphUrl
                    schema {
                        raw
                    }
                    title
                }
                topImages {
                    topImageOne {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                    topImageTwo {
                        altText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(quality: 100)
                            }
                        }
                    }
                }
            }
        }
    `);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...data.wordpressPage.seo } />
            <section className={ classNames.aboutUs } ref={ ref }>
                { data.wordpressPage.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.content } />
                ) }
                <div className={ classNames.aboutUs }>
                    <div className={ classNames.topImages }>
                        <GatsbyImage
                            alt={ data.wordpressPage.topImages.topImageOne.altText }
                            className={ classNames.topImage }
                            image={ data.wordpressPage.topImages.topImageOne.localFile.childImageSharp.gatsbyImageData }
                        />
                        <GatsbyImage
                            alt={ data.wordpressPage.topImages.topImageTwo.altText }
                            className={ classNames.topImage }
                            image={ data.wordpressPage.topImages.topImageTwo.localFile.childImageSharp.gatsbyImageData }
                        />
                    </div>
                    { data.wordpressPage.extraContent && (
                        <Gutenberg className={ classNames.gutenberg } content={ data.wordpressPage.extraContent } />
                    ) }
                    <DigitalHeroesBlock className={ classNames.digitalHeroesBlock } />
                </div>
                <WhatWeStandForBlock />
                <div className={ classNames.bottomImages }>
                    <GatsbyImage
                        alt={ data.wordpressPage.bottomImages.bottomImageOne.altText }
                        className={ classNames.bottomImage }
                        image={
                            data.wordpressPage.bottomImages.bottomImageOne.localFile.childImageSharp.gatsbyImageData
                        }
                    />
                    <GatsbyImage
                        alt={ data.wordpressPage.bottomImages.bottomImageTwo.altText }
                        className={ classNames.bottomImage }
                        image={
                            data.wordpressPage.bottomImages.bottomImageTwo.localFile.childImageSharp.gatsbyImageData
                        }
                    />
                </div>
                <WhatWeCanDoForYouBlock />
            </section>
        </>
    );
};

export default forwardRef(AboutUs);
