// extracted by mini-css-extract-plugin
export var digitalHeroesBlock = "DigitalHeroesBlock-module--digitalHeroesBlock--2iQHa";
export var container = "DigitalHeroesBlock-module--container--1G-wa";
export var arrows = "DigitalHeroesBlock-module--arrows--11Qj_";
export var nextArrowButton = "DigitalHeroesBlock-module--nextArrowButton--Wu5B3";
export var prevArrowButton = "DigitalHeroesBlock-module--prevArrowButton--GSCRu";
export var employee = "DigitalHeroesBlock-module--employee--19FIH";
export var image = "DigitalHeroesBlock-module--image--28_bO";
export var overlay = "DigitalHeroesBlock-module--overlay--3EYGQ";
export var heading = "DigitalHeroesBlock-module--heading--ttkDk";
export var position = "DigitalHeroesBlock-module--position--3l3bW";