import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type ChalkboardTeacherIconProps
 */
export type ChalkboardTeacherIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const ChalkboardTeacherIcon
 */
const ChalkboardTeacherIcon = (props: ChalkboardTeacherIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="512" ref={ ref } viewBox="0 0 640 512" width="640">
            <path d="M608 0H192c-17.67 0-32 14.33-32 32v96c-53.02 0-96 42.98-96 96s42.98 96 96 96 96-42.98 96-96c0-41.74-26.8-76.9-64-90.12V32h416v352h-64v-64c0-17.67-14.33-32-32-32H384c-17.67 0-32 14.33-32 32v64h-46.66c-.59-.94-1.03-1.96-1.65-2.88-17.25-25.62-46.67-39.11-76.9-39.11C199 342.02 192.02 352 160 352c-31.97 0-38.95-9.98-66.79-9.98-30.23 0-59.65 13.48-76.9 39.11C6.01 396.42 0 414.84 0 434.67V472c0 22.09 17.91 40 40 40h240c22.09 0 40-17.91 40-40v-37.33c0-6.41-.84-12.6-2.04-18.67H608c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32zM224 224c0 35.29-28.71 64-64 64s-64-28.71-64-64 28.71-64 64-64 64 28.71 64 64zm64 248c0 4.41-3.59 8-8 8H40c-4.41 0-8-3.59-8-8v-37.33c0-12.79 3.75-25.13 10.85-35.67 10.53-15.64 29.35-24.98 50.36-24.98 21.8 0 29.99 9.98 66.79 9.98 36.79 0 45.01-9.98 66.79-9.98 21 0 39.83 9.34 50.36 24.98 7.1 10.54 10.85 22.88 10.85 35.67V472zm224-88H384v-64h128v64z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(ChalkboardTeacherIcon);
