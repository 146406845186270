import React from "react";

import { DetailedHTMLProps } from "react";
import { Ref } from "react";
import { SVGAttributes } from "react";

import { forwardRef } from "react";

/**
 * @type ShoePrintsIconProps
 */
export type ShoePrintsIconProps = Omit<DetailedHTMLProps<SVGAttributes<SVGSVGElement>, SVGSVGElement>, "children">;

/**
 * @const ShoePrintsIcon
 */
const ShoePrintsIcon = (props: ShoePrintsIconProps, ref: Ref<SVGSVGElement>) => {
    return (
        <svg { ...props } height="512" ref={ ref } viewBox="0 0 640 512" width="640">
            <path d="M337.46 272c-34.91 0-76.16 14.06-104.73 34.29-24.79 17.55-44.52 34.29-104.73 34.29H64c-35.35 0-64 30.7-64 68.57S28.65 480 64 480h64l57.53 14.82C217.42 504.3 250.17 512 293.3 512 403.84 512 512 456.84 512 374.86 512 306.29 427.82 272 337.46 272zM112 448H64c-17.05 0-32-18.16-32-38.86 0-20.17 14.36-36.57 32-36.57h48V448zm81.51 15.84L144 451.08v-79.11c55.33-2.95 80.75-20.66 103.08-36.62C338.33 270.38 480 316.73 480 374.86c0 62.15-121.49 137.92-286.49 88.98zM421.29 0c-43.42 0-76.17 7.78-107.77 17.17L256 32h-64c-35.35 0-64 32.98-64 70.86 0 37.87 28.65 68.57 64 68.57h64c60.2 0 79.94 16.73 104.73 34.29C389.3 225.94 430.54 240 465.46 240 555.82 240 640 205.71 640 137.14 640 55.84 533.12 0 421.29 0zM240 139.43h-48c-17.64 0-32-16.4-32-36.57C160 82.16 174.95 64 192 64h48v75.43zM465.46 208c-27.58 0-62.24-11.41-86.24-28.4-23.71-16.89-48.76-36.45-107.22-39.56V60.92l50.65-13.07C352.05 39.1 381.72 32 421.29 32 473.38 32 608 58.02 608 137.14c0 46.52-71.71 70.86-142.54 70.86z" fill="currentColor" />
        </svg>
    );
};

export default forwardRef(ShoePrintsIcon);
