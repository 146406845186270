import React from "react";
import SwiperCore from "swiper";

import { GatsbyImage } from "gatsby-plugin-image";
import { Navigation } from "swiper";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Ref } from "react";
import { Swiper } from "swiper/react";
import { SwiperSlide } from "swiper/react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useRef } from "react";
import { useTranslation } from "react-i18next";

import ArrowLeftIcon from "@Js/Component/Icon/ArrowLeftIcon";
import ArrowRightIcon from "@Js/Component/Icon/ArrowRightIcon";
import Heading from "@Js/Component/Heading";

import useMediaQuery from "@Js/Hook/useMediaQuery";

import "swiper/css";

import * as classNames from "@Css/Component/Block/DigitalHeroesBlock.module.scss";

SwiperCore.use([Navigation]);

/**
 * @type AllWordpressEmployeeData
 */
type AllWordpressEmployeeData = {
    allWordpressEmployee: {
        edges: {
            node: {
                featuredImage?: {
                    node: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                id: string;
                position: {
                    position: string;
                };
                title: string;
            };
        }[];
    };
};

/**
 * @type DigitalHeroesBlockProps
 */
export type DigitalHeroesBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const DigitalHeroesBlock
 */
const DigitalHeroesBlock = (props: DigitalHeroesBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const isDesktop = useMediaQuery("(min-width: 1024px)");

    const next = useRef<HTMLButtonElement | null>();
    const prev = useRef<HTMLButtonElement | null>();

    const data: AllWordpressEmployeeData = useStaticQuery(graphql`
        query {
            allWordpressEmployee(sort: { fields: title }) {
                edges {
                    node {
                        featuredImage {
                            node {
                                localFile {
                                    childImageSharp {
                                        gatsbyImageData(aspectRatio: 0.75, quality: 100)
                                    }
                                }
                            }
                        }
                        id
                        position {
                            position
                        }
                        title
                    }
                }
            }
        }
    `);

    const onSwiperInit = (swiper: SwiperCore) => {
        if (swiper.params.navigation) {

            // @ts-ignore
            swiper.params.navigation.prevEl = prev.current;

            // @ts-ignore
            swiper.params.navigation.nextEl = next.current;
        }

        swiper.navigation.init();
        swiper.navigation.update();
    };

    return (
        <section { ...restProps } className={ clsx(classNames.digitalHeroesBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <div className={ classNames.arrows }>
                    <button
                        aria-label={ t("Previous") }
                        className={ classNames.prevArrowButton }
                        ref={ (ref) => prev.current = ref }
                    >
                        <ArrowLeftIcon />
                    </button>
                    <button
                        aria-label={ t("Next") }
                        className={ classNames.nextArrowButton }
                        ref={ (ref) => next.current = ref }
                    >
                        <ArrowRightIcon />
                    </button>
                </div>
                <Swiper
                    onInit={ onSwiperInit }
                    slidesPerView={ isDesktop ? 3 : 1 }
                    spaceBetween={ isDesktop ? 50 : 20 }
                >
                    { data.allWordpressEmployee.edges.map((edge) => (
                        <SwiperSlide key={ edge.node.id }>
                            <div className={ classNames.employee }>
                                { edge.node.featuredImage && (
                                    <GatsbyImage
                                        alt={ edge.node.title }
                                        className={ classNames.image }
                                        image={ edge.node.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                    />
                                ) }
                                <div className={ classNames.overlay } />
                                <section className={ classNames.container }>
                                    <Heading className={ classNames.heading }>{ edge.node.title }</Heading>
                                    <Heading className={ classNames.position } element="h2">
                                        { edge.node.position.position }
                                    </Heading>
                                </section>
                            </div>
                        </SwiperSlide>
                    )) }
                </Swiper>
            </div>
        </section>
    );
};

export default forwardRef(DigitalHeroesBlock);
