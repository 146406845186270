import React from "react";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import BookOpenIcon from "@Js/Component/Icon/BookOpenIcon";
import ChalkboardTeacherIcon from "@Js/Component/Icon/ChalkboardTeacherIcon";
import FireIcon from "@Js/Component/Icon/FireIcon";
import Heading from "@Js/Component/Heading";
import ShoePrintsIcon from "@Js/Component/Icon/ShoePrintsIcon";
import UsersCrownIcon from "@Js/Component/Icon/UsersCrownIcon";

import * as classNames from "@Css/Component/Block/WhatWeStandForBlock.module.scss";

/**
 * @type WordpressData
 */
type WordpressData = {
    wordpress: {
        otherSettings: {
            coreValues: {
                coreValueOneDescription: string;
                coreValueOneTitle: string;
                coreValueTwoDescription: string;
                coreValueTwoTitle: string;
                coreValueThreeDescription: string;
                coreValueThreeTitle: string;
                coreValueFourDescription: string;
                coreValueFourTitle: string;
                coreValueFiveDescription: string;
                coreValueFiveTitle: string;
            };
        };
    };
};

/**
 * @type WhatWeStandForBlockProps
 */
export type WhatWeStandForBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children">;

/**
 * @const WhatWeStandForBlock
 */
const WhatWeStandForBlock = (props: WhatWeStandForBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const data: WordpressData = useStaticQuery(graphql`
        query {
            wordpress {
                otherSettings {
                    coreValues {
                        coreValueOneDescription
                        coreValueOneTitle
                        coreValueTwoDescription
                        coreValueTwoTitle
                        coreValueThreeDescription
                        coreValueThreeTitle
                        coreValueFourDescription
                        coreValueFourTitle
                        coreValueFiveDescription
                        coreValueFiveTitle
                    }
                }
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.whatWeStandForBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <Heading>{ t("What We Stand For") }</Heading>
                <div className={ classNames.coreValues }>
                    <div className={ classNames.left }>
                        <section className={ classNames.coreValue }>
                            <UsersCrownIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>
                                { data.wordpress.otherSettings.coreValues.coreValueOneTitle }
                            </Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.coreValues.coreValueOneDescription }
                            </div>
                        </section>
                        <section className={ classNames.coreValue }>
                            <BookOpenIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>
                                { data.wordpress.otherSettings.coreValues.coreValueTwoTitle }
                            </Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.coreValues.coreValueTwoDescription }
                            </div>
                        </section>
                        <section className={ classNames.coreValue }>
                            <ChalkboardTeacherIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>
                                { data.wordpress.otherSettings.coreValues.coreValueThreeTitle }
                            </Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.coreValues.coreValueThreeDescription }
                            </div>
                        </section>
                    </div>
                    <div className={ classNames.right }>
                        <section className={ classNames.coreValue }>
                            <ShoePrintsIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>
                                { data.wordpress.otherSettings.coreValues.coreValueFourTitle }
                            </Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.coreValues.coreValueFourDescription }
                            </div>
                        </section>
                        <section className={ classNames.coreValue }>
                            <FireIcon className={ classNames.icon } />
                            <Heading className={ classNames.heading }>
                                { data.wordpress.otherSettings.coreValues.coreValueFiveTitle }
                            </Heading>
                            <div className={ classNames.content }>
                                { data.wordpress.otherSettings.coreValues.coreValueFiveDescription }
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(WhatWeStandForBlock);
